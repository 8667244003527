<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- fullname -->
      <template #cell(fullname)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.fullname }}
            </div>
          </div>
        </div>
      </template>

      <!-- typebrand -->
      <template #cell(typebrand)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.typebrand }}
            </div>
          </div>
        </div>
      </template>

      <!-- plaque -->
      <template #cell(plaque)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.plaque }}
            </div>
          </div>
        </div>
      </template>

      <!-- InquiryCar -->
      <template #cell(InquiryCar)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.InquiryCar }}
            </div>
          </div>
        </div>
      </template>

      <!-- InquiryDriver -->
      <template #cell(InquiryDriver)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.InquiryDriver }}
            </div>
          </div>
        </div>
      </template>

      <!-- rate -->
      <template #cell(rate)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.rate }}
            </div>
          </div>
        </div>
      </template>

      <!-- detail -->
      <template #cell(detail)>
        <div class="d-flex align-items-center">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
            >نمایش</b-button
          >
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: "fullname", label: "نام و نام خانوادگی" },
        { key: "typebrand", label: "نوع و برند وسیله نقلیه" },
        { key: "plaque", label: "پلاک" },
        { key: "InquiryCar", label: "استعلام خودرو" },
        { key: "InquiryDriver", label: "استعلام راننده" },
        { key: "rate", label: "امتیاز" },
        { key: "detail", label: "جزئیات" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
