<template>
  <section id="dashboard-ecommerce">
    <!-- <b-row class="match-height">
      <b-col xl="4" md="6">
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col xl="8" md="6">
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col cols="12">
        <b-card>
          <b-card-title>قراردادهای جاری</b-card-title>
          <contract-table :table-data="data.ContractTable" />
        </b-card>
        <b-card>
          <b-card-title>قراردادهای گذشته</b-card-title>
          <last-contract-table :table-data="data.LastContractTable" />
        </b-card>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import ContractTable from "./ContractTable.vue";
import LastContractTable from "./LastContractTable.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ContractTable,
    LastContractTable,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    this.$http.get("/contract/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData();
      this.data.congratulations.name =
        userData.fullName.split(" ")[0] || userData.username;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
